import { defineStore } from "pinia";
import { ICompanyData } from "./CompaniesStore";
import axios, { AxiosError } from 'axios'
import { IStoreData } from "@/models/StoreModel";
import router from "@/router";
import { campCreateUnlinkedClone, campIsEqual } from "@/composables/DataValidation";

type TCompany = ICompanyData | null
type TStore = IStoreData | null

interface IFilterBaseV2Store<TForm> {
  _updated: boolean
  _error: boolean
  _company: TCompany
  _store: TStore
  _form: TForm
  _formCache: TForm
  _pageId: null | number
}

export interface ICompanySessionResponse {
  data: {
    error: boolean
    message: string
    data: ICompanyData | null
    errorCode: string
  }
}

export interface IStoreSessionResponse {
  data: {
    error: boolean,
    message: string,
    data: IStoreData | null,
    errorCode: string,
  }
}

export const useFilterBaseV2Store = defineStore("FilterBaseV2Store", {
  state: <TForm>(): IFilterBaseV2Store<TForm> => {
    return {
      _company: null,
      _store: null,
      _form: null as TForm,
      _formCache: null as TForm,
      _pageId: null,
      _updated: false,
      _error: false,
    };
  },
  actions: {
    async getCompanyAndStoreInSession() {
      try {
        this._error = false

        const [ companySessionResponse, storeSessionResponse ] : [ ICompanySessionResponse, IStoreSessionResponse ] = [
          await axios.post('/api/getCompanySession'),
          await axios.post('/api/getStoreSession')
        ]

        this._company = companySessionResponse.data.data
        this._store = storeSessionResponse.data.data
      } catch (error) {
        this._error = true
        this._company = null
        this._store = null
        if (error instanceof AxiosError)
          throw new Error(error.response?.data.message || 'Algo deu errado!');
        else
          throw new Error('Algo deu errado!');
      }
    },
    async setCompanyAndStoreInSession(companyId: number | null, storeId: number | null) {
      try {
        this._updated = false
        this._error = false
        const currentCompanyID = this.company?.id || null
        const currentStoreID = this.store?.id || null

        if((currentCompanyID !== companyId) || (currentStoreID !== storeId)) {
          const [ companySessionResponse, storeSessionResponse ] : [ ICompanySessionResponse, IStoreSessionResponse ] = [
            await axios.post('/api/setCompanySession', { id: !!companyId ? companyId : null }),
            await axios.post('/api/setStoreSession', { id: !!storeId ? storeId : null })
          ]
  
          /** check for changes: company */
          const reponseCompanyID = companySessionResponse.data.data?.id || null
          if (!this._updated && currentCompanyID !== reponseCompanyID)
            this._updated = true
  
          /** check for changes: store */
          const reponseStoreID = storeSessionResponse.data.data?.id || null
          if (!this._updated && currentStoreID !== reponseStoreID)
            this._updated = true
  
          /** update: company or store */
          this._company = companySessionResponse.data.data
          this._store = storeSessionResponse.data.data
        }

        /** check for changes: form */
        if (!this._updated && !campIsEqual(this._formCache, this._form))
          this._updated = true

        this._formCache = campCreateUnlinkedClone(this._form)
      } catch (error) {
        this._updated = false
        this._error = true
        this._company = null
        this._store = null
        if (error instanceof AxiosError)
          throw new Error(error.response?.data.message || 'Algo deu errado!');
        else
          throw new Error('Algo deu errado!');
      }
    },
    async getCompanyInSession(redirectToSetCompany = true, optimized = false) { /** WARNING! Once all the global filters have been replaced by the v2 global filter, change the optimized value to TRUE. */
      if(this._company && optimized)
        return this._company

      await this.getCompanyAndStoreInSession()
      if(this._company)
        return this._company

      if(!this._company && redirectToSetCompany)
        router.push({ name: "FilterCompanySession" })

      return null
    },
    async getStoreInSession(redirectToSetCompany = true, optimized = false) { /** WARNING! Once all the global filters have been replaced by the v2 global filter, change the optimized value to TRUE. */
      if(this._store && optimized)
        return this._store

      await this.getCompanyAndStoreInSession()
      if(this._store)
        return this._store

      if(!this._company && redirectToSetCompany)
        router.push({ name: "FilterCompanySession" })

      return null
    },
    setSearchForm<TForm>(form: TForm) {
      this._updated = false
      this._form = campCreateUnlinkedClone<TForm>(form as TForm)
    },
    getSearchForm<TForm>(): TForm {
      return campCreateUnlinkedClone<TForm>(this._form as TForm)
    },
    setSearchFormCache<TForm>(form: TForm) {
      this._formCache = campCreateUnlinkedClone<TForm>(form as TForm)
    },
    getSearchFormCache<TForm>(): TForm {
      return campCreateUnlinkedClone<TForm>(this._formCache as TForm)
    },
    setPageID(pageId: number | null) {
      this._pageId = pageId
    },
    checkForUpdates(): boolean {
      return this._updated
    },
    reset() {
      /** WARNING!!! */
      /** Global values cannot be reset (COMPANY & STORE) */
      this._updated = false
      this._error = false
      this._form = null
      this._formCache = null
      this._pageId = null
    }
  },
  getters: {
    company(): ICompanyData | null {
      return this._company
    },
    store(): IStoreData | null {
      return this._store
    },
    pageID(): null | number {
      return this._pageId
    }
  },
});
