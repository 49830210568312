import { defineStore } from "pinia";
import axios from "axios";

export const useProductStore = defineStore("ProductStore", {
  state: () => {
    return {
      products: [],
      isExcel: false,
      openList: false
    }
  },
  actions: {
    async getProductValidation(products: any, isExcel: any) {
      this.products = products
      this.isExcel = isExcel
    },
    setOpenList() {
      this.openList = !this.openList
    }
  },
  getters: {
    getExcel():boolean {
        return this.isExcel

    },
    getProduct():any {
      return this.products

  }
  }
});
